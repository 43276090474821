var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    {
      staticClass: "main-colaboradores",
      staticStyle: {
        "background-color": "#f7f7fd",
        border: "0px solid #e7e7fa !important",
        "padding-top": "52px",
      },
    },
    [
      _c("Header", {
        attrs: {
          title: "Trabalhador",
          subtitle: "Monitoramento das informações do trabalhador",
          itemsBreadCrumb: _vm.itemsBreadCrumb,
          hasUpdateInfo: false,
          hasAddButton: false,
        },
      }),
      _c("div", [_c("TabsColab")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }