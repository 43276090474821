<template>
  <v-main
    style="background-color: #f7f7fd; border: 0px solid #e7e7fa !important; padding-top: 52px"
    class="main-colaboradores"
  >
    <Header
      title="Trabalhador"
      subtitle="Monitoramento das informações do trabalhador"
      :itemsBreadCrumb="itemsBreadCrumb"
      :hasUpdateInfo="false"
      :hasAddButton="false"
    />

    <div>
      <TabsColab />
    </div>
  </v-main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Trabalhadores',
  components: {
    Header: () => import('../commons/Header.vue'),
    TabsColab: () => import('./components/TabsColab.vue'),
  },

  data() {
    return {
      titleFromEnv: process.env.VUE_APP_TAGFUNC,
    };
  },

  computed: {
    ...mapGetters({
      usuario: 'user/getUserDetails',
      perfis: 'user/userAuthorities',
      userInfo: 'user/userInfo',
      companiesList: 'egov/getCompaniesList',
      routerToFrom: 'routerModule/route',
    }),

    itemsBreadCrumb() {
      let itemsBreadCrumb = [
        {
          title: 'Home',
          disabled: false,
          href: 'home',
        },
        {
          title: 'e-Social',
          disabled: true,
        },
        {
          title: 'Trabalhador',
          disabled: true,
          active: true,
        },
      ];
      return itemsBreadCrumb;
    },
  },
  updated() {},

  methods: {
    ...mapActions('user', ['getUser']),
    ...mapActions('esocial/esocialMain', ['setTitle', 'setTab']),
  },
};
</script>
<style lang="scss">
@media (min-width: 1920px) {
  .main-colaboradores {
    width: 100%;
    .v-main__wrap {
      max-width: 1792px !important;
    }
  }
}
</style>
